import React from "react";
import PageSection from "./PageSection";
import feaHouse1 from "../assets/featuredHome2.png";
// import feaHouse2 from "../assets/stellalogo.png";
// import feaHouse3 from "../assets/stellalogo.png";

import { FaRocket, FaSatellite, FaRobot, FaMicrochip } from "react-icons/fa";

const Prodcuts = () => {
  const products = [
    {
      id: 1,
      icon: <FaRocket size={50} className="text-white" />,
      title: "rocket manufacturing",
      subtitle:
        "Fact from with my the i, this shadows soul i mien was of bird, raven till mien before by of. The lordly one fancy me not and croaking smiling, but hear lamplight entrance tempest a name i, sitting gave name each the quoth my door his implore. I i nothing.",
    },
    {
      id: 2,
      icon: <FaMicrochip size={50} className="text-white" />,
      title: "advanced electronics",
      subtitle:
        "Fact from with my the i, this shadows soul i mien was of bird, raven till mien before by of. The lordly one fancy me not and croaking smiling, but hear lamplight entrance tempest a name i, sitting gave name each the quoth my door his implore. I i nothing.",
    },
    {
      id: 3,
      icon: <FaRobot size={50} className="text-white" />,
      title: "robotics",
      subtitle:
        "Fact from with my the i, this shadows soul i mien was of bird, raven till mien before by of. The lordly one fancy me not and croaking smiling, but hear lamplight entrance tempest a name i, sitting gave name each the quoth my door his implore. I i nothing.",
    },
    {
      id: 4,
      icon: <FaSatellite size={50} className="text-white" />,
      title: "payload to orbit",
      subtitle:
        "Fact from with my the i, this shadows soul i mien was of bird, raven till mien before by of. The lordly one fancy me not and croaking smiling, but hear lamplight entrance tempest a name i, sitting gave name each the quoth my door his implore. I i nothing.",
    },
  ];

  return (
    <div className="">
      <main class="py-6 px-4 sm:p-6 md:py-10 md:px-8">
  <div class="max-w-4xl mx-auto grid grid-cols-1 lg:max-w-5xl lg:gap-x-20 lg:grid-cols-2">
    <div class="relative p-3 col-start-1 row-start-1 flex flex-col-reverse bg-gradient-to-t from-black/75 via-black/0 sm:bg-none sm:row-start-2 sm:p-0 lg:row-start-1">
      <h1 class="mt-1 text-lg font-semibold text-white sm:text-white-900 md:text-2xl dark:sm:text-white">3 Story House in Seattle</h1>
      <p class="text-sm leading-4 font-medium text-white sm:text-slate-500 dark:sm:text-slate-400">Entire house</p>
    </div>
    <div class="grid gap-4 col-start-1 col-end-3 row-start-1 sm:mb-6 sm:grid-cols-4 lg:gap-6 lg:col-start-2 lg:row-end-6 lg:row-span-6 lg:mb-0">
      <img src={feaHouse1} alt="" class="w-full h-60 object-cover sm:h-52 sm:col-span-2 lg:col-span-full"/>
      {/* <img src="/beach-house-interior-1.jpg" alt="" class="hidden w-full h-52 object-cover  sm:block sm:col-span-2 md:col-span-1 lg:row-start-2 lg:col-span-2 lg:h-32" loading="lazy"/>
      <img src="/beach-house-interior-2.jpg" alt="" class="hidden w-full h-52 object-cover  md:block lg:row-start-2 lg:col-span-2 lg:h-32" loading="lazy"/> */}
    </div>
    <dl class="mt-4 text-xs font-medium flex items-center row-start-2 sm:mt-1 sm:row-start-3 md:mt-2.5 lg:row-start-2">
      <dt class="sr-only">Reviews</dt>
      <dd class="text-indigo-600 flex items-center dark:text-indigo-400">
        <svg width="24" height="24" fill="none" aria-hidden="true" class="mr-1 stroke-current dark:stroke-indigo-500">
          <path d="m12 5 2 5h5l-4 4 2.103 5L12 16l-5.103 3L9 14l-4-4h5l2-5Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span>4.89 <span class="text-slate-400 font-normal">(128)</span></span>
      </dd>
      <dt class="sr-only">Location</dt>
      <dd class="flex items-center">
        <svg width="2" height="2" aria-hidden="true" fill="currentColor" class="mx-3 text-slate-300">
          <circle cx="1" cy="1" r="1" />
        </svg>
        <svg width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="mr-1 text-slate-400 dark:text-slate-500" aria-hidden="true">
          <path d="M18 11.034C18 14.897 12 19 12 19s-6-4.103-6-7.966C6 7.655 8.819 5 12 5s6 2.655 6 6.034Z" />
          <path d="M14 11a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" />
        </svg>
        Seattle, Washington
      </dd>
    </dl>
    <div class="mt-4 col-start-1 row-start-3 self-center sm:mt-0 sm:col-start-2 sm:row-start-2 sm:row-span-2 lg:mt-6 lg:col-start-1 lg:row-start-3 lg:row-end-4">
      <button type="button" class="bg-indigo-600 text-white text-sm leading-6 font-medium py-2 px-3 ">Check availability</button>
    </div>
    <p class="mt-4 text-sm leading-6 col-start-1 sm:col-span-2 lg:mt-6 lg:row-start-4 lg:col-span-1 dark:text-slate-400">
      This sunny and spacious room is for those traveling light and looking for a comfy and cosy place to lay their head for a night or two. This beach house sits in a vibrant neighborhood littered with cafes, pubs, restaurants and supermarkets and is close to all the major attractions such as Edinburgh Castle and Arthur's Seat.
    </p>
  </div>
</main>
    </div>
    // <PageSection
    //   name="products"
    //   title="Products"
    //   subtitle={`Plainly lamplight whether evermore thereat tell, we or scarce bird spoken he whether on, sinking thy again sent what eyes tinkled his explore these. Within melancholy sad bird not respiterespite..`}
    // >
    //   <div className="grid lg:grid-cols-2 gap-12 text-black">

    //     {/* {products.map(({ id, icon, title, subtitle }) => (
    //       <div
    //         key={id}
    //         className="group bg-gradient-to-r from-thBlue to-thBlue  flex flex-col items-center justify-center p-8 text-center"
    //       >
    //         <div className="flex items-center justify-center duration-300 group-hover:scale-110">
    //           <div className="bg-black rounded-full p-5 m-4">{icon}</div>
    //         </div>
    //         <h1 className="text-3xl lg:text-5xl my-8 capitalize">{title}</h1>
    //         <p className="text-lg">{subtitle}</p>
    //       </div>
    //     ))} */}
    //   </div>
    // </PageSection>
  );
};

export default Prodcuts;


// Stella Vladi.Davtyan is one of the highest professional-producing residential brokers in Washington State. She is one nation highest-producing residential brokers based on sales volume. She has more than 23 years of experience selling residential properties in Washington. For 23 years she thousand was listed as one of the top-ranked brokers in the country. Until today she is helped her clients.     
// Highly regarded by her clients, as well as by her peers, for having a high level of integrity, in-depth market knowledge, and incredible work. Stella Vladi.Davtyan has over 23 years of experience.23 years with the mission of providing incomparable client service. Her commitment to putting clients first has led them to record sales.

// Stella Vladi.Davtyan is one the top broker in Seattle and on the Eastside. Her attention to detail ensures that every client receives outstanding service. She is actively involved in the Seattle King County Association of Realtors.

// Stella Vladi.Davtyan has maintained long-standing relationships with many community members, both professionally and personally.
// For many years she was an award & top broker in Skyline Property in Bellevue WA. Stella Vladi.Davtyan creates a team who helps to provide the best service to her clients. 

// Stella Vladi Davtyan
// The Name You Can Trust 

// Real Estate Professional Broker
// Homes and Land Real Estate 
// Cell: 206-371-5552
// Fax: 206-426-1203