import { useState } from "react";
import Customers from "./components/Customers";
import DevApi from "./components/DevApi";
import HeroSection from "./components/HeroSection";
import Navbar from "./components/Navbar";
import Prodcuts from "./components/Prodcuts";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


function App() {
// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBybw4eEpjy-88JDcLbf1aALVK7IKWfp3o",
  authDomain: "stella-vladi-davtyan-30735.firebaseapp.com",
  projectId: "stella-vladi-davtyan-30735",
  storageBucket: "stella-vladi-davtyan-30735.appspot.com",
  messagingSenderId: "289276488036",
  appId: "1:289276488036:web:884c198574f6ea8cd43271",
  measurementId: "G-07TB9SZY0E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

  const [isMenuShown, setIsMenuShown] = useState(false);

  return (
    <div>
      <Navbar isMenuShown={isMenuShown} setIsMenuShown={setIsMenuShown} />
      <HeroSection isMenuShown={isMenuShown} />
      <Prodcuts />
      {/* <DevApi /> */}
      <Customers class="decoration-orange-300" />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
