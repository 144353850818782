import React from "react";
import PageSection from "./PageSection";
import {imageHouse} from "../assets/featuredHome2.png";
import {
  FaAmazon,
  FaApple,
  FaFacebook,
  FaGoogle,
  FaSnapchatGhost,
} from "react-icons/fa";




const Customers = () => {
  const facts = [
    {
      id: 1,
      title: "$20M",
      subtitle: "Total real estate sales in the last 10 years.",
    },
    {
      id: 2,
      title: "120+",
      subtitle: "Satisfied clients in the past year.",
    },
    {
      id: 3,
      title: "23+ years",
      subtitle: "Combined experience in the real estate industry.",
    },
    {
      id: 4,
      title: "5-star",
      subtitle: "Customer satisfaction rating on all our sales.",
    },
  ];

  const companies = [
    {
      id: 1,
      icon: <FaGoogle size={25} />,
      title: "google",
    },
    {
      id: 2,
      icon: <FaFacebook size={25} />,
      title: "facebook",
    },
    {
      id: 3,
      icon: <FaAmazon size={25} />,
      title: "amazon",
    },
    {
      id: 4,
      icon: <FaApple size={25} />,
      title: "apple",
    },
    {
      id: 5,
      icon: <FaSnapchatGhost size={25} />,
      title: "snapchat",
    },
  ];

  return (
    
    <PageSection
      name="customers"
      title="Customers"
      subtitle={``}
    >

      <>


        <div className="grid lg:grid-cols-4 text-left gap-8 mb-16">
          {facts.map(({ id, title, subtitle }) => (
            <div
              key={id}
              className="odd:bg-thBlue even:bg-thBlue text-black p-4"
            >
              <h1 className="text-3xl font-bold mb-2">{title}</h1>
              <p>{subtitle}</p>
            </div>
          ))}
        </div>

        <div className="flex flex-col lg:flex-row items-center justify-around gap-6">
          {/* {companies.map(({ id, icon, title }) => (
            <div
              className="flex items-center capitalize p-4 rounded-lg"
              key={id}
            >
              {icon}
              <p className="ml-2">{title}</p>
            </div>
          ))} */}
        </div>
      </>
    </PageSection>
  );
};

export default Customers;
