import React from "react";
import GradientBtn from "./GradientBtn";
import contactImage2 from "../assets/logo2stella.png";

import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-scroll";

const Navbar = ({ isMenuShown, setIsMenuShown }) => {
  const links = [
    // {
    //   id: 1,
    //   link: "home",
    // },
    // {
    //   id: 2,
    //   link: "Home",
    // },
    // {
    //   id: 3,
    //   link: "developer API",
    // },
    {
      id: 4,
      name: "Contact & Email",
      link: "contact",
    },
    {
      id: 5,
      name: "206-371-5552",
      link: "206-371-5552",
    },
  ];

  return (
    <>
      <div className="absolute w-full h-24 backdrop-blur-sm bg-black/80 text-white z-20">
        <div className="flex justify-between items-center max-w-screen-xl mx-auto px-4 h-full drop-shadow-2xl ">
        <div className="object-center: w-40 h-40 drop-shadow-2xl border-spacing-5 border-y-zinc-700 outline-4 outline-slate-900">
            <img src={contactImage2} />
        </div>

          <div className="hidden lg:flex items-center">
            <ul className="flex">
              {links.map(({ id, link, name }) => (
                <li
                  key={id}
                  className="p-4 uppercase duration-200 hover:text-thBlue cursor-pointer"
                >
                  <Link to={link} smooth duration={500}>
                    {name}
                  </Link>
                </li>
              ))}
            </ul>
            {/* <GradientBtn className="ml-4 capitalize" title="get Stella Vladi Davtyan" /> */}
          </div>

          <div
            onClick={() => setIsMenuShown(!isMenuShown)}
            className="block lg:hidden cursor-pointer"
          >
            {isMenuShown ? <FaTimes size={30} /> : <FaBars size={30} />}
          </div>
        </div>
      </div>

      <div
        className={`w-full bg-black text-white absolute z-10 left-0 h-fit py-12 lg:hidden flex justify-center text-center text-2xl duration-500 ${
          isMenuShown ? "top-24 -b-2xl opacity-95" : "top-[-100%]"
        }`}
      >
        <ul>
          {links.map(({ id, link }) => (
            <li key={id} className="p-4 uppercase cursor-pointer">
              <Link
                onClick={() => setIsMenuShown(false)}
                to={link}
                smooth
                duration={500}
              >
                {link}
              </Link>
            </li>
          ))}
          {/* <GradientBtn className="mt-10 capitalize" title="get Stella Vladi Davtyan" /> */}
        </ul>
      </div>
    </>
  );
};

export default Navbar;
