import React from "react";
import PageSection from "./PageSection";

import contactImage2 from "../assets/StellaResProfile.jpg";

import { FaFacebook, FaLinkedin } from "react-icons/fa";
import ContactForm from "./ContactForm";
import Link from "react";

const Contact = () => {
  return (
    <PageSection
      name="contact"
      title="Contact"
      subtitle={`Please enter your information below and we'll get back to you as soon as possible.`}
    >
      <div className="outline-2 flex flex-col lg:flex-row gap-8 mt-4 outline-double outline-3 outline-offset-2">
        {/* left */}

        <div className="w-full lg:w-1/2 h-full py-16 flex items-center flex-col justify-center">
          <img 
            src={contactImage2}
            alt="contact us"
            className="rounded-full object-cover w-72 outline outline-5 outline-offset-5"
          />

          <p className=" py-16 max-w-md" >
          With over 23 years of experience selling residential properties in Washington, Stella Vladi Davtyan is a highly-regarded professional in the real estate industry. She consistently ranks as one of the top-producing residential brokers in the state, and her impressive sales volume has earned her a place among the nation's highest producers.
          <p className=" py-2 max-w-md">

Stella's dedication to her clients is unmatched, and her in-depth market knowledge and unwavering integrity have earned her a reputation as a trusted advisor. Her commitment to providing incomparable client service is evident in her record sales, and her clients consistently praise her for her incredible work.
</p>

<p className=" py-2 max-w-md">

Throughout her career, Stella has remained focused on putting her clients first, and this approach has led to her continued success in the industry. With a mission to provide exceptional service and a track record of delivering results, Stella Vladi Davtyan is a true leader in the world of residential real estate.
          </p>
          </p>
          <div className="grid grid-cols-2 mx-auto w-4/5 gap-10">
            <div className="flex items-center justify-center rounded-full shadow-md shadow-thPurple hover:scale-105 duration-200 p-3 cursor-pointer">
              <FaFacebook size={25} >
                <Link to="https://www.facebook.com/StellaVladiDavtyan" />
              </FaFacebook>
            </div>
            <div className="flex items-center justify-center rounded-full shadow-md shadow-thPurple hover:scale-105 duration-200 p-3 cursor-pointer">
              <FaLinkedin size={25} href="https://www.linkedin.com/in/stella-vladi-davtyan-0b0b1b1b/" />
            </div>
          </div>
        </div>

        {/* right */}

        <div className="w-full lg:w-1/2 h-full rounded-xl p-4 py-40">
          <ContactForm />
        </div>
      </div>
    </PageSection>
  );
};

export default Contact;
