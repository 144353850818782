import React, { useRef, useState } from "react";

import heroVideo from "../assets/prd5.mp4";
import { FaPauseCircle, FaPlayCircle } from "react-icons/fa";
import GradientBtn from "./GradientBtn";

const HeroSection = ({ isMenuShown }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(true);

  const videoRef = useRef();

  const handleVideoPause = () => {
    videoRef.current.pause();
    setIsVideoPlaying(false);
  };

  const handleVideoPlay = () => {
    videoRef.current.play();
    setIsVideoPlaying(true);
  };

  return (
    <div className="flex items-end justify-center w-full h-screen text-center">
      <video
        ref={videoRef}
        src={heroVideo}
        autoPlay
        loop
        muted
        className="object-cover h-full w-full absolute -z-10"
      />

      <div
        className={`backdrop-blur-sm bg-black/40 p-8 flex flex-col items-center justify-center duration-500 w-full ${
          "bg-black/30"
        } ${"opacity-100"}`}
      >
      <div class="grid grid-cols-1 divide-y py-1 ">
              <h1 className="text-5xl lg:text-7xl py-1" >Find your next home with <h1 className="py-4 font-extrabold dark:text-white">Stella Vladi Davtyan</h1></h1>

              <h1 class=" py-2 mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-6xl">
                <span class=" text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-cyan-500">Your Future Is Here</span></h1>

              {/* <h1 className="text-5xl lg:text-7xl capitalize mb-12 py-3">
              </h1>

              <p class="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.</p> */}

      </div>
      </div>
    </div>
  );
};

export default HeroSection;
